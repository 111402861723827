var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm no-margin",
          attrs: { title: "상세" },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [_c("q-btn-group", { attrs: { outline: "" } })],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "점검명",
                          name: "checkName",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkName,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkName", $$v)
                          },
                          expression: "preStartupCheck.checkName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-2" },
                    [
                      _c("c-select", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          codeGroupCd: "PSR_EQUIPMENT_STATUS_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "psrEquipmentStatusCd",
                          label: "설비상태",
                        },
                        model: {
                          value: _vm.preStartupCheck.psrEquipmentStatusCd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "psrEquipmentStatusCd",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.psrEquipmentStatusCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-2" },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          disabled: _vm.disabled,
                          editable: _vm.editInfo,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.preStartupCheck.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "plantCd", $$v)
                          },
                          expression: "preStartupCheck.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-2" },
                    [
                      _c("c-select", {
                        attrs: {
                          required: true,
                          codeGroupCd: "PSR_CHECK_PERIOD_CD",
                          disabled: _vm.disabled,
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "psrCheckPeriodCd",
                          label: "점검시기",
                        },
                        model: {
                          value: _vm.preStartupCheck.psrCheckPeriodCd,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "psrCheckPeriodCd",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.psrCheckPeriodCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-2" },
                    [
                      _c("c-dept", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          type: "edit",
                          label: "점검부서",
                          name: "checkDeptCd",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkDeptCd", $$v)
                          },
                          expression: "preStartupCheck.checkDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-2" },
                    [
                      _c("c-field", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          plantCd: _vm.preStartupCheck.plantCd,
                          type: "user",
                          label: "점검총책임자",
                          name: "checkCancarrierId",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkCancarrierId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "checkCancarrierId",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.checkCancarrierId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          required: true,
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          label: "점검목적",
                          name: "checkPurpose",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkPurpose,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "checkPurpose", $$v)
                          },
                          expression: "preStartupCheck.checkPurpose",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-2" },
                    [
                      _c("c-moc", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          isSubmit: _vm.saveCallData,
                          document: _vm.preStartupCheck,
                          documentId: _vm.param.sopPrestartupCheckId,
                          documentTitle: "checkName",
                          mocRelatedTaskCd: "RT00000015",
                          label: "MOC번호",
                          name: "sopMocId",
                        },
                        on: {
                          "update:document": function ($event) {
                            _vm.preStartupCheck = $event
                          },
                        },
                        model: {
                          value: _vm.preStartupCheck.sopMocId,
                          callback: function ($$v) {
                            _vm.$set(_vm.preStartupCheck, "sopMocId", $$v)
                          },
                          expression: "preStartupCheck.sopMocId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editInfo,
                          disabled: _vm.disabled,
                          rows: 3,
                          label: "점검결과종합",
                          name: "checkResultTotal",
                        },
                        model: {
                          value: _vm.preStartupCheck.checkResultTotal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.preStartupCheck,
                              "checkResultTotal",
                              $$v
                            )
                          },
                          expression: "preStartupCheck.checkResultTotal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("c-tab", {
        staticClass: "q-mt-sm preStartup-tab-no-padding",
        attrs: {
          type: "tabcard",
          align: "left",
          editable: _vm.editInfo,
          tabItems: _vm.preStartupCheck.equipments,
          inlineLabel: true,
          addTab: _vm.editInfo && !_vm.disabled,
          removeTab: _vm.editInfo && !_vm.disabled,
          removeConditionCol: "checkCompleteFlag",
          removeConditionVal: "Y",
        },
        on: {
          "update:tabItems": function ($event) {
            return _vm.$set(_vm.preStartupCheck, "equipments", $event)
          },
          "update:tab-items": function ($event) {
            return _vm.$set(_vm.preStartupCheck, "equipments", $event)
          },
          addTab: _vm.addTab,
          removeTab: _vm.removeTab,
          tabClick: _vm.tabClick,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    param: _vm.param,
                    preStartupCheck: _vm.preStartupCheck,
                    equipmentCd: tab.equipmentCd,
                    research: _vm.research,
                  },
                  on: {
                    "update:preStartupCheck": function ($event) {
                      _vm.preStartupCheck = $event
                    },
                    "update:pre-startup-check": function ($event) {
                      _vm.preStartupCheck = $event
                    },
                    "update:research": function ($event) {
                      _vm.research = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.editInfo &&
                        !_vm.disabled &&
                        Boolean(_vm.param.sopPrestartupCheckId),
                      expression:
                        "editInfo && !disabled && Boolean(param.sopPrestartupCheckId)",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isComplete,
                    url: _vm.saveUrl,
                    param: _vm.preStartupCheck,
                    mappingType: "PUT",
                    label: "점검완료",
                    icon: "check",
                  },
                  on: {
                    beforeAction: _vm.completePreStartupCheck,
                    btnCallback: _vm.completeCallback,
                  },
                }),
                _c("br"),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editInfo && !_vm.disabled,
                      expression: "editInfo && !disabled",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isSave,
                    url: _vm.saveUrl,
                    param: _vm.preStartupCheck,
                    mappingType: _vm.mappingType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.savePreStartupCheck,
                    btnCallback: _vm.saveCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.editInfo &&
                        !_vm.disabled &&
                        Boolean(_vm.param.sopPrestartupCheckId),
                      expression:
                        "editInfo && !disabled && Boolean(param.sopPrestartupCheckId)",
                    },
                  ],
                  attrs: { flat: true, label: "삭제", icon: "remove" },
                  on: { btnClicked: _vm.removePreStartupCheck },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }